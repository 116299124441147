import Head from '../components/head.js';
import UpperBody from '../components/home/upperBody.js';
import CenterBody from '../components/home/centerBody.js';
import SixSteps from '../components/home/sixsteps.js';
import LowerBody from '../components/lowerBody.js';
import Carousel from '../components/home/carousel.js';

export default function Home() {
  return (
    <>
      <Head title="CODICEO • Les développeurs de vos solutions informatiques" description="Nous vous accompagnons dans le développement de vos solutions innovantes : logiciel & application sur mesure, application mobile, site web" />
      <main>
        <UpperBody />
        <CenterBody />
        <SixSteps />
        <Carousel />
        <LowerBody />
      </main>
    </>
  )
};

