import img_0 from '../imgs/lottie/img_0.svg';
import img_1 from '../imgs/lottie/img_1.svg';
import img_2 from '../imgs/lottie/img_2.svg';

export default {
    "v": "5.7.13",
    "fr": 29.9700012207031,
    "ip": 0,
    "op": 180.00000733155,
    "w": 1200,
    "h": 771,
    "nm": "codiceo",
    "ddd": 0,
    "assets": [
        {
            "id": "image_0",
            "w": 1200,
            "h": 771,
            "u": "",
            "p": img_0.src,
            "e": 0
        },
        {
            "id": "image_1",
            "w": 1200,
            "h": 771,
            "u": "",
            "p": img_1.src,
            "e": 0
        },
        {
            "id": "image_2",
            "w": 1200,
            "h": 771,
            "u": "",
            "p": img_2.src,
            "e": 0
        },
        {
            "id": "comp_0",
            "nm": "home_page",
            "layers": [
                {
                    "ddd": 0,
                    "ind": 1,
                    "ty": 2,
                    "nm": "Calque 4",
                    "refId": "image_0",
                    "sr": 1,
                    "ks": {
                        "o": {
                            "a": 0,
                            "k": 100,
                            "ix": 11
                        },
                        "r": {
                            "a": 0,
                            "k": 0,
                            "ix": 10
                        },
                        "p": {
                            "a": 0,
                            "k": [
                                600,
                                385,
                                0
                            ],
                            "ix": 2,
                            "l": 2
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                600,
                                385.5,
                                0
                            ],
                            "ix": 1,
                            "l": 2
                        },
                        "s": {
                            "a": 0,
                            "k": [
                                100,
                                100,
                                100
                            ],
                            "ix": 6,
                            "l": 2
                        }
                    },
                    "ao": 0,
                    "ip": 0,
                    "op": 180.00000733155,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 2,
                    "ty": 2,
                    "nm": "projet",
                    "refId": "image_1",
                    "sr": 1,
                    "ks": {
                        "o": {
                            "a": 0,
                            "k": 100,
                            "ix": 11
                        },
                        "r": {
                            "a": 0,
                            "k": 0,
                            "ix": 10
                        },
                        "p": {
                            "a": 0,
                            "k": [
                                600,
                                385.5,
                                0
                            ],
                            "ix": 2,
                            "l": 2
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                600,
                                385.5,
                                0
                            ],
                            "ix": 1,
                            "l": 2
                        },
                        "s": {
                            "a": 0,
                            "k": [
                                100,
                                100,
                                100
                            ],
                            "ix": 6,
                            "l": 2
                        }
                    },
                    "ao": 0,
                    "ip": 0,
                    "op": 180.00000733155,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 3,
                    "ty": 2,
                    "nm": "Calque 2",
                    "refId": "image_2",
                    "sr": 1,
                    "ks": {
                        "o": {
                            "a": 0,
                            "k": 100,
                            "ix": 11
                        },
                        "r": {
                            "a": 0,
                            "k": 0,
                            "ix": 10
                        },
                        "p": {
                            "a": 0,
                            "k": [
                                600,
                                385.5,
                                0
                            ],
                            "ix": 2,
                            "l": 2
                        },
                        "a": {
                            "a": 0,
                            "k": [
                                600,
                                385.5,
                                0
                            ],
                            "ix": 1,
                            "l": 2
                        },
                        "s": {
                            "a": 0,
                            "k": [
                                100,
                                100,
                                100
                            ],
                            "ix": 6,
                            "l": 2
                        }
                    },
                    "ao": 0,
                    "ip": 0,
                    "op": 180.00000733155,
                    "st": 0,
                    "bm": 0
                }
            ]
        }
    ],
    "layers": [
        {
            "ddd": 0,
            "ind": 1,
            "ty": 0,
            "nm": "home_page",
            "refId": "comp_0",
            "sr": 1,
            "ks": {
                "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 11
                },
                "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 10
                },
                "p": {
                    "a": 0,
                    "k": [
                        600,
                        385.5,
                        0
                    ],
                    "ix": 2,
                    "l": 2
                },
                "a": {
                    "a": 0,
                    "k": [
                        600,
                        385.5,
                        0
                    ],
                    "ix": 1,
                    "l": 2
                },
                "s": {
                    "a": 0,
                    "k": [
                        100,
                        100,
                        100
                    ],
                    "ix": 6,
                    "l": 2
                }
            },
            "ao": 0,
            "hasMask": true,
            "masksProperties": [
                {
                    "inv": false,
                    "mode": "n",
                    "pt": {
                        "a": 0,
                        "k": {
                            "i": [
                                [
                                    0,
                                    0.502
                                ],
                                [
                                    -59.5,
                                    -50
                                ]
                            ],
                            "o": [
                                [
                                    72,
                                    -15.497
                                ],
                                [
                                    0,
                                    -0.5
                                ]
                            ],
                            "v": [
                                [
                                    323,
                                    247.997
                                ],
                                [
                                    547,
                                    334.5
                                ]
                            ],
                            "c": false
                        },
                        "ix": 1
                    },
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 3
                    },
                    "x": {
                        "a": 0,
                        "k": 0,
                        "ix": 4
                    },
                    "nm": "Masque 1"
                },
                {
                    "inv": false,
                    "mode": "n",
                    "pt": {
                        "a": 0,
                        "k": {
                            "i": [
                                [
                                    -12.5,
                                    0.5
                                ],
                                [
                                    -11,
                                    17.499
                                ]
                            ],
                            "o": [
                                [
                                    59,
                                    45.5
                                ],
                                [
                                    0.266,
                                    -0.423
                                ]
                            ],
                            "v": [
                                [
                                    322,
                                    516.5
                                ],
                                [
                                    564.5,
                                    443.001
                                ]
                            ],
                            "c": false
                        },
                        "ix": 1
                    },
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 3
                    },
                    "x": {
                        "a": 0,
                        "k": 0,
                        "ix": 4
                    },
                    "nm": "Masque 2"
                },
                {
                    "inv": false,
                    "mode": "n",
                    "pt": {
                        "a": 0,
                        "k": {
                            "i": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "o": [
                                [
                                    0,
                                    0
                                ],
                                [
                                    0,
                                    0
                                ]
                            ],
                            "v": [
                                [
                                    684.875,
                                    386.25
                                ],
                                [
                                    836.5,
                                    386.5
                                ]
                            ],
                            "c": false
                        },
                        "ix": 1
                    },
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 3
                    },
                    "x": {
                        "a": 0,
                        "k": 0,
                        "ix": 4
                    },
                    "nm": "Masque 3"
                }
            ],
            "w": 1200,
            "h": 771,
            "ip": 0,
            "op": 180.00000733155,
            "st": 0,
            "bm": 0
        },
        {
            "ddd": 0,
            "ind": 2,
            "ty": 4,
            "nm": "Fleche 3.2",
            "cl": "2",
            "sr": 1,
            "ks": {
                "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 11
                },
                "r": {
                    "a": 0,
                    "k": 90,
                    "ix": 10
                },
                "p": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": -44.94,
                            "s": [
                                684.875,
                                386.25,
                                0
                            ],
                            "to": [
                                0,
                                0,
                                0
                            ],
                            "ti": [
                                0,
                                0,
                                0
                            ]
                        },
                        {
                            "t": 45.0000018328876,
                            "s": [
                                836.5,
                                386.5,
                                0
                            ]
                        }
                    ],
                    "ix": 2,
                    "l": 2,
                    "x": "var $bm_rt;\n$bm_rt = loopOut('cycle', 0);"
                },
                "a": {
                    "a": 0,
                    "k": [
                        -113.92,
                        -140.445,
                        0
                    ],
                    "ix": 1,
                    "l": 2
                },
                "s": {
                    "a": 0,
                    "k": [
                        101.415,
                        100.392,
                        100
                    ],
                    "ix": 6,
                    "l": 2
                }
            },
            "ao": 1,
            "shapes": [
                {
                    "ty": "gr",
                    "it": [
                        {
                            "ty": "sr",
                            "sy": 2,
                            "d": 1,
                            "pt": {
                                "a": 0,
                                "k": 3,
                                "ix": 3
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 4
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "or": {
                                "a": 0,
                                "k": 15,
                                "ix": 7
                            },
                            "os": {
                                "a": 0,
                                "k": 0,
                                "ix": 9
                            },
                            "ix": 1,
                            "nm": "Tracé polyétoile 1",
                            "mn": "ADBE Vector Shape - Star",
                            "hd": false
                        },
                        {
                            "ty": "rd",
                            "nm": "Sommets arrondis 1",
                            "r": {
                                "a": 0,
                                "k": 3,
                                "ix": 1
                            },
                            "ix": 2,
                            "mn": "ADBE Vector Filter - RC",
                            "hd": false
                        },
                        {
                            "ty": "st",
                            "c": {
                                "a": 0,
                                "k": [
                                    1,
                                    1,
                                    1,
                                    1
                                ],
                                "ix": 3
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 4
                            },
                            "w": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "lc": 1,
                            "lj": 2,
                            "bm": 0,
                            "nm": "Contour 1",
                            "mn": "ADBE Vector Graphic - Stroke",
                            "hd": false
                        },
                        {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [
                                    0.541176497936,
                                    0.635294139385,
                                    0.807843208313,
                                    1
                                ],
                                "ix": 4
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 5
                            },
                            "r": 1,
                            "bm": 0,
                            "nm": "Fond 1",
                            "mn": "ADBE Vector Graphic - Fill",
                            "hd": false
                        },
                        {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [
                                    -113.912,
                                    -138.897
                                ],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [
                                    83.645,
                                    52.174
                                ],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0.142,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transformer "
                        }
                    ],
                    "nm": "Polyétoile 1",
                    "np": 4,
                    "cix": 2,
                    "bm": 0,
                    "ix": 1,
                    "mn": "ADBE Vector Group",
                    "hd": false
                }
            ],
            "ip": 0,
            "op": 180.00000733155,
            "st": 0,
            "bm": 0
        },
        {
            "ddd": 0,
            "ind": 3,
            "ty": 4,
            "nm": "Fleche 3.1",
            "cl": "1",
            "sr": 1,
            "ks": {
                "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 11
                },
                "r": {
                    "a": 0,
                    "k": 90,
                    "ix": 10
                },
                "p": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 0,
                            "s": [
                                684.875,
                                386.25,
                                0
                            ],
                            "to": [
                                0,
                                0,
                                0
                            ],
                            "ti": [
                                0,
                                0,
                                0
                            ]
                        },
                        {
                            "t": 90.0000036657751,
                            "s": [
                                836.5,
                                386.5,
                                0
                            ]
                        }
                    ],
                    "ix": 2,
                    "l": 2,
                    "x": "var $bm_rt;\n$bm_rt = loopOut('cycle', 0);"
                },
                "a": {
                    "a": 0,
                    "k": [
                        -113.92,
                        -140.445,
                        0
                    ],
                    "ix": 1,
                    "l": 2
                },
                "s": {
                    "a": 0,
                    "k": [
                        101.415,
                        100.392,
                        100
                    ],
                    "ix": 6,
                    "l": 2
                }
            },
            "ao": 1,
            "shapes": [
                {
                    "ty": "gr",
                    "it": [
                        {
                            "ty": "sr",
                            "sy": 2,
                            "d": 1,
                            "pt": {
                                "a": 0,
                                "k": 3,
                                "ix": 3
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 4
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "or": {
                                "a": 0,
                                "k": 15,
                                "ix": 7
                            },
                            "os": {
                                "a": 0,
                                "k": 0,
                                "ix": 9
                            },
                            "ix": 1,
                            "nm": "Tracé polyétoile 1",
                            "mn": "ADBE Vector Shape - Star",
                            "hd": false
                        },
                        {
                            "ty": "rd",
                            "nm": "Sommets arrondis 1",
                            "r": {
                                "a": 0,
                                "k": 3,
                                "ix": 1
                            },
                            "ix": 2,
                            "mn": "ADBE Vector Filter - RC",
                            "hd": false
                        },
                        {
                            "ty": "st",
                            "c": {
                                "a": 0,
                                "k": [
                                    1,
                                    1,
                                    1,
                                    1
                                ],
                                "ix": 3
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 4
                            },
                            "w": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "lc": 1,
                            "lj": 2,
                            "bm": 0,
                            "nm": "Contour 1",
                            "mn": "ADBE Vector Graphic - Stroke",
                            "hd": false
                        },
                        {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [
                                    0.541176497936,
                                    0.635294139385,
                                    0.807843208313,
                                    1
                                ],
                                "ix": 4
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 5
                            },
                            "r": 1,
                            "bm": 0,
                            "nm": "Fond 1",
                            "mn": "ADBE Vector Graphic - Fill",
                            "hd": false
                        },
                        {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [
                                    -113.912,
                                    -138.897
                                ],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [
                                    83.645,
                                    52.174
                                ],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0.142,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transformer "
                        }
                    ],
                    "nm": "Polyétoile 1",
                    "np": 4,
                    "cix": 2,
                    "bm": 0,
                    "ix": 1,
                    "mn": "ADBE Vector Group",
                    "hd": false
                }
            ],
            "ip": 0,
            "op": 180.00000733155,
            "st": 0,
            "bm": 0
        },
        {
            "ddd": 0,
            "ind": 4,
            "ty": 4,
            "nm": "Fleche 2.3",
            "cl": "3",
            "sr": 1,
            "ks": {
                "o": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 148,
                            "s": [
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 149,
                            "s": [
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 150,
                            "s": [
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 151,
                            "s": [
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 328,
                            "s": [
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 329,
                            "s": [
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 330,
                            "s": [
                                0
                            ]
                        },
                        {
                            "t": 331.000013481906,
                            "s": [
                                100
                            ]
                        }
                    ],
                    "ix": 11
                },
                "r": {
                    "a": 0,
                    "k": 90,
                    "ix": 10
                },
                "p": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": -30,
                            "s": [
                                322,
                                516.5,
                                0
                            ],
                            "to": [
                                56.817,
                                43.816,
                                0
                            ],
                            "ti": [
                                -21.739,
                                22.534,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 149,
                            "s": [
                                562.63,
                                445.276,
                                0
                            ],
                            "to": [
                                0.835,
                                -0.866,
                                0
                            ],
                            "ti": [
                                -12.5,
                                0.5,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 150,
                            "s": [
                                322,
                                516.5,
                                0
                            ],
                            "to": [
                                56.817,
                                43.816,
                                0
                            ],
                            "ti": [
                                -21.739,
                                22.534,
                                0
                            ]
                        },
                        {
                            "t": 329.000013400445,
                            "s": [
                                562.63,
                                445.276,
                                0
                            ]
                        }
                    ],
                    "ix": 2,
                    "l": 2
                },
                "a": {
                    "a": 0,
                    "k": [
                        -113.92,
                        -140.445,
                        0
                    ],
                    "ix": 1,
                    "l": 2
                },
                "s": {
                    "a": 0,
                    "k": [
                        101.415,
                        100.392,
                        100
                    ],
                    "ix": 6,
                    "l": 2
                }
            },
            "ao": 1,
            "shapes": [
                {
                    "ty": "gr",
                    "it": [
                        {
                            "ty": "sr",
                            "sy": 2,
                            "d": 1,
                            "pt": {
                                "a": 0,
                                "k": 3,
                                "ix": 3
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 4
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "or": {
                                "a": 0,
                                "k": 15,
                                "ix": 7
                            },
                            "os": {
                                "a": 0,
                                "k": 0,
                                "ix": 9
                            },
                            "ix": 1,
                            "nm": "Tracé polyétoile 1",
                            "mn": "ADBE Vector Shape - Star",
                            "hd": false
                        },
                        {
                            "ty": "rd",
                            "nm": "Sommets arrondis 1",
                            "r": {
                                "a": 0,
                                "k": 3,
                                "ix": 1
                            },
                            "ix": 2,
                            "mn": "ADBE Vector Filter - RC",
                            "hd": false
                        },
                        {
                            "ty": "st",
                            "c": {
                                "a": 0,
                                "k": [
                                    1,
                                    1,
                                    1,
                                    1
                                ],
                                "ix": 3
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 4
                            },
                            "w": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "lc": 1,
                            "lj": 2,
                            "bm": 0,
                            "nm": "Contour 1",
                            "mn": "ADBE Vector Graphic - Stroke",
                            "hd": false
                        },
                        {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [
                                    0.494117676978,
                                    0.815686334348,
                                    0.713725490196,
                                    1
                                ],
                                "ix": 4
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 5
                            },
                            "r": 1,
                            "bm": 0,
                            "nm": "Fond 1",
                            "mn": "ADBE Vector Graphic - Fill",
                            "hd": false
                        },
                        {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [
                                    -113.912,
                                    -138.897
                                ],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [
                                    83.645,
                                    52.174
                                ],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0.142,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transformer "
                        }
                    ],
                    "nm": "Polyétoile 1",
                    "np": 4,
                    "cix": 2,
                    "bm": 0,
                    "ix": 1,
                    "mn": "ADBE Vector Group",
                    "hd": false
                }
            ],
            "ip": 0,
            "op": 180.00000733155,
            "st": 0,
            "bm": 0
        },
        {
            "ddd": 0,
            "ind": 5,
            "ty": 4,
            "nm": "Fleche 2.2",
            "cl": "2",
            "sr": 1,
            "ks": {
                "o": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 88,
                            "s": [
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 89,
                            "s": [
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 90,
                            "s": [
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 91,
                            "s": [
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 268,
                            "s": [
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 269,
                            "s": [
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 270,
                            "s": [
                                0
                            ]
                        },
                        {
                            "t": 271.000011038056,
                            "s": [
                                100
                            ]
                        }
                    ],
                    "ix": 11
                },
                "r": {
                    "a": 0,
                    "k": 90,
                    "ix": 10
                },
                "p": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": -90,
                            "s": [
                                322,
                                516.5,
                                0
                            ],
                            "to": [
                                56.817,
                                43.816,
                                0
                            ],
                            "ti": [
                                -21.739,
                                22.534,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 89,
                            "s": [
                                562.63,
                                445.276,
                                0
                            ],
                            "to": [
                                0.835,
                                -0.866,
                                0
                            ],
                            "ti": [
                                -12.5,
                                0.5,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 90,
                            "s": [
                                322,
                                516.5,
                                0
                            ],
                            "to": [
                                56.817,
                                43.816,
                                0
                            ],
                            "ti": [
                                -21.739,
                                22.534,
                                0
                            ]
                        },
                        {
                            "t": 269.000010956595,
                            "s": [
                                562.63,
                                445.276,
                                0
                            ]
                        }
                    ],
                    "ix": 2,
                    "l": 2
                },
                "a": {
                    "a": 0,
                    "k": [
                        -113.92,
                        -140.445,
                        0
                    ],
                    "ix": 1,
                    "l": 2
                },
                "s": {
                    "a": 0,
                    "k": [
                        101.415,
                        100.392,
                        100
                    ],
                    "ix": 6,
                    "l": 2
                }
            },
            "ao": 1,
            "shapes": [
                {
                    "ty": "gr",
                    "it": [
                        {
                            "ty": "sr",
                            "sy": 2,
                            "d": 1,
                            "pt": {
                                "a": 0,
                                "k": 3,
                                "ix": 3
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 4
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "or": {
                                "a": 0,
                                "k": 15,
                                "ix": 7
                            },
                            "os": {
                                "a": 0,
                                "k": 0,
                                "ix": 9
                            },
                            "ix": 1,
                            "nm": "Tracé polyétoile 1",
                            "mn": "ADBE Vector Shape - Star",
                            "hd": false
                        },
                        {
                            "ty": "rd",
                            "nm": "Sommets arrondis 1",
                            "r": {
                                "a": 0,
                                "k": 3,
                                "ix": 1
                            },
                            "ix": 2,
                            "mn": "ADBE Vector Filter - RC",
                            "hd": false
                        },
                        {
                            "ty": "st",
                            "c": {
                                "a": 0,
                                "k": [
                                    1,
                                    1,
                                    1,
                                    1
                                ],
                                "ix": 3
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 4
                            },
                            "w": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "lc": 1,
                            "lj": 2,
                            "bm": 0,
                            "nm": "Contour 1",
                            "mn": "ADBE Vector Graphic - Stroke",
                            "hd": false
                        },
                        {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [
                                    0.494117676978,
                                    0.815686334348,
                                    0.713725490196,
                                    1
                                ],
                                "ix": 4
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 5
                            },
                            "r": 1,
                            "bm": 0,
                            "nm": "Fond 1",
                            "mn": "ADBE Vector Graphic - Fill",
                            "hd": false
                        },
                        {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [
                                    -113.912,
                                    -138.897
                                ],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [
                                    83.645,
                                    52.174
                                ],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0.142,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transformer "
                        }
                    ],
                    "nm": "Polyétoile 1",
                    "np": 4,
                    "cix": 2,
                    "bm": 0,
                    "ix": 1,
                    "mn": "ADBE Vector Group",
                    "hd": false
                }
            ],
            "ip": 0,
            "op": 180.00000733155,
            "st": 0,
            "bm": 0
        },
        {
            "ddd": 0,
            "ind": 6,
            "ty": 4,
            "nm": "Fleche 2.1",
            "cl": "1",
            "sr": 1,
            "ks": {
                "o": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 28,
                            "s": [
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 29,
                            "s": [
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 30,
                            "s": [
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 31,
                            "s": [
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 208,
                            "s": [
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 209,
                            "s": [
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 210,
                            "s": [
                                0
                            ]
                        },
                        {
                            "t": 211.000008594206,
                            "s": [
                                100
                            ]
                        }
                    ],
                    "ix": 11
                },
                "r": {
                    "a": 0,
                    "k": 90,
                    "ix": 10
                },
                "p": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": -150,
                            "s": [
                                322,
                                516.5,
                                0
                            ],
                            "to": [
                                56.817,
                                43.816,
                                0
                            ],
                            "ti": [
                                -21.739,
                                22.534,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 29,
                            "s": [
                                562.63,
                                445.276,
                                0
                            ],
                            "to": [
                                0.835,
                                -0.866,
                                0
                            ],
                            "ti": [
                                -12.5,
                                0.5,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 30,
                            "s": [
                                322,
                                516.5,
                                0
                            ],
                            "to": [
                                56.817,
                                43.816,
                                0
                            ],
                            "ti": [
                                -21.739,
                                22.534,
                                0
                            ]
                        },
                        {
                            "t": 209.000008512745,
                            "s": [
                                562.63,
                                445.276,
                                0
                            ]
                        }
                    ],
                    "ix": 2,
                    "l": 2
                },
                "a": {
                    "a": 0,
                    "k": [
                        -113.92,
                        -140.445,
                        0
                    ],
                    "ix": 1,
                    "l": 2
                },
                "s": {
                    "a": 0,
                    "k": [
                        101.415,
                        100.392,
                        100
                    ],
                    "ix": 6,
                    "l": 2
                }
            },
            "ao": 1,
            "shapes": [
                {
                    "ty": "gr",
                    "it": [
                        {
                            "ty": "sr",
                            "sy": 2,
                            "d": 1,
                            "pt": {
                                "a": 0,
                                "k": 3,
                                "ix": 3
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 4
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "or": {
                                "a": 0,
                                "k": 15,
                                "ix": 7
                            },
                            "os": {
                                "a": 0,
                                "k": 0,
                                "ix": 9
                            },
                            "ix": 1,
                            "nm": "Tracé polyétoile 1",
                            "mn": "ADBE Vector Shape - Star",
                            "hd": false
                        },
                        {
                            "ty": "rd",
                            "nm": "Sommets arrondis 1",
                            "r": {
                                "a": 0,
                                "k": 3,
                                "ix": 1
                            },
                            "ix": 2,
                            "mn": "ADBE Vector Filter - RC",
                            "hd": false
                        },
                        {
                            "ty": "st",
                            "c": {
                                "a": 0,
                                "k": [
                                    1,
                                    1,
                                    1,
                                    1
                                ],
                                "ix": 3
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 4
                            },
                            "w": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "lc": 1,
                            "lj": 2,
                            "bm": 0,
                            "nm": "Contour 1",
                            "mn": "ADBE Vector Graphic - Stroke",
                            "hd": false
                        },
                        {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [
                                    0.494117676978,
                                    0.815686334348,
                                    0.713725490196,
                                    1
                                ],
                                "ix": 4
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 5
                            },
                            "r": 1,
                            "bm": 0,
                            "nm": "Fond 1",
                            "mn": "ADBE Vector Graphic - Fill",
                            "hd": false
                        },
                        {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [
                                    -113.912,
                                    -138.897
                                ],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [
                                    83.645,
                                    52.174
                                ],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0.142,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transformer "
                        }
                    ],
                    "nm": "Polyétoile 1",
                    "np": 4,
                    "cix": 2,
                    "bm": 0,
                    "ix": 1,
                    "mn": "ADBE Vector Group",
                    "hd": false
                }
            ],
            "ip": 0,
            "op": 180.00000733155,
            "st": 0,
            "bm": 0
        },
        {
            "ddd": 0,
            "ind": 7,
            "ty": 4,
            "nm": "Fleche 1.3",
            "cl": "3",
            "sr": 1,
            "ks": {
                "o": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": -60,
                            "s": [
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": -59,
                            "s": [
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 118,
                            "s": [
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 119,
                            "s": [
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 120,
                            "s": [
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 121,
                            "s": [
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 299,
                            "s": [
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 300,
                            "s": [
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 301,
                            "s": [
                                0
                            ]
                        },
                        {
                            "t": 302.000012300712,
                            "s": [
                                100
                            ]
                        }
                    ],
                    "ix": 11
                },
                "r": {
                    "a": 0,
                    "k": 90,
                    "ix": 10
                },
                "p": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": -60,
                            "s": [
                                323,
                                247.997,
                                0
                            ],
                            "to": [
                                71.156,
                                -15.315,
                                0
                            ],
                            "ti": [
                                -59.56,
                                -49.451,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 119,
                            "s": [
                                544.894,
                                332.741,
                                0
                            ],
                            "to": [
                                0.707,
                                0.587,
                                0
                            ],
                            "ti": [
                                0,
                                0.502,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 120,
                            "s": [
                                323,
                                247.997,
                                0
                            ],
                            "to": [
                                71.156,
                                -15.315,
                                0
                            ],
                            "ti": [
                                -59.56,
                                -49.451,
                                0
                            ]
                        },
                        {
                            "t": 299.00001217852,
                            "s": [
                                544.894,
                                332.741,
                                0
                            ]
                        }
                    ],
                    "ix": 2,
                    "l": 2
                },
                "a": {
                    "a": 0,
                    "k": [
                        -113.92,
                        -140.445,
                        0
                    ],
                    "ix": 1,
                    "l": 2
                },
                "s": {
                    "a": 0,
                    "k": [
                        101.415,
                        100.392,
                        100
                    ],
                    "ix": 6,
                    "l": 2
                }
            },
            "ao": 1,
            "shapes": [
                {
                    "ty": "gr",
                    "it": [
                        {
                            "ty": "sr",
                            "sy": 2,
                            "d": 1,
                            "pt": {
                                "a": 0,
                                "k": 3,
                                "ix": 3
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 4
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "or": {
                                "a": 0,
                                "k": 15,
                                "ix": 7
                            },
                            "os": {
                                "a": 0,
                                "k": 0,
                                "ix": 9
                            },
                            "ix": 1,
                            "nm": "Tracé polyétoile 1",
                            "mn": "ADBE Vector Shape - Star",
                            "hd": false
                        },
                        {
                            "ty": "rd",
                            "nm": "Sommets arrondis 1",
                            "r": {
                                "a": 0,
                                "k": 3,
                                "ix": 1
                            },
                            "ix": 2,
                            "mn": "ADBE Vector Filter - RC",
                            "hd": false
                        },
                        {
                            "ty": "st",
                            "c": {
                                "a": 0,
                                "k": [
                                    1,
                                    1,
                                    1,
                                    1
                                ],
                                "ix": 3
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 4
                            },
                            "w": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "lc": 1,
                            "lj": 2,
                            "bm": 0,
                            "nm": "Contour 1",
                            "mn": "ADBE Vector Graphic - Stroke",
                            "hd": false
                        },
                        {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [
                                    0.494117676978,
                                    0.815686334348,
                                    0.713725490196,
                                    1
                                ],
                                "ix": 4
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 5
                            },
                            "r": 1,
                            "bm": 0,
                            "nm": "Fond 1",
                            "mn": "ADBE Vector Graphic - Fill",
                            "hd": false
                        },
                        {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [
                                    -113.912,
                                    -138.897
                                ],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [
                                    83.645,
                                    52.174
                                ],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0.142,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transformer "
                        }
                    ],
                    "nm": "Polyétoile 1",
                    "np": 4,
                    "cix": 2,
                    "bm": 0,
                    "ix": 1,
                    "mn": "ADBE Vector Group",
                    "hd": false
                }
            ],
            "ip": 0,
            "op": 180.00000733155,
            "st": 0,
            "bm": 0
        },
        {
            "ddd": 0,
            "ind": 8,
            "ty": 4,
            "nm": "Fleche 1.2",
            "cl": "2",
            "sr": 1,
            "ks": {
                "o": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": -120,
                            "s": [
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": -119,
                            "s": [
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 58,
                            "s": [
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 59,
                            "s": [
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 60,
                            "s": [
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 61,
                            "s": [
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 239,
                            "s": [
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 240,
                            "s": [
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 241,
                            "s": [
                                0
                            ]
                        },
                        {
                            "t": 242.000009856862,
                            "s": [
                                100
                            ]
                        }
                    ],
                    "ix": 11
                },
                "r": {
                    "a": 0,
                    "k": 90,
                    "ix": 10
                },
                "p": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": -120,
                            "s": [
                                323,
                                247.997,
                                0
                            ],
                            "to": [
                                71.156,
                                -15.315,
                                0
                            ],
                            "ti": [
                                -59.56,
                                -49.451,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 59,
                            "s": [
                                544.894,
                                332.741,
                                0
                            ],
                            "to": [
                                0.707,
                                0.587,
                                0
                            ],
                            "ti": [
                                0,
                                0.502,
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 60,
                            "s": [
                                323,
                                247.997,
                                0
                            ],
                            "to": [
                                71.156,
                                -15.315,
                                0
                            ],
                            "ti": [
                                -59.56,
                                -49.451,
                                0
                            ]
                        },
                        {
                            "t": 239.00000973467,
                            "s": [
                                544.894,
                                332.741,
                                0
                            ]
                        }
                    ],
                    "ix": 2,
                    "l": 2
                },
                "a": {
                    "a": 0,
                    "k": [
                        -113.92,
                        -140.445,
                        0
                    ],
                    "ix": 1,
                    "l": 2
                },
                "s": {
                    "a": 0,
                    "k": [
                        101.415,
                        100.392,
                        100
                    ],
                    "ix": 6,
                    "l": 2
                }
            },
            "ao": 1,
            "shapes": [
                {
                    "ty": "gr",
                    "it": [
                        {
                            "ty": "sr",
                            "sy": 2,
                            "d": 1,
                            "pt": {
                                "a": 0,
                                "k": 3,
                                "ix": 3
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 4
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "or": {
                                "a": 0,
                                "k": 15,
                                "ix": 7
                            },
                            "os": {
                                "a": 0,
                                "k": 0,
                                "ix": 9
                            },
                            "ix": 1,
                            "nm": "Tracé polyétoile 1",
                            "mn": "ADBE Vector Shape - Star",
                            "hd": false
                        },
                        {
                            "ty": "rd",
                            "nm": "Sommets arrondis 1",
                            "r": {
                                "a": 0,
                                "k": 3,
                                "ix": 1
                            },
                            "ix": 2,
                            "mn": "ADBE Vector Filter - RC",
                            "hd": false
                        },
                        {
                            "ty": "st",
                            "c": {
                                "a": 0,
                                "k": [
                                    1,
                                    1,
                                    1,
                                    1
                                ],
                                "ix": 3
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 4
                            },
                            "w": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "lc": 1,
                            "lj": 2,
                            "bm": 0,
                            "nm": "Contour 1",
                            "mn": "ADBE Vector Graphic - Stroke",
                            "hd": false
                        },
                        {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [
                                    0.494117676978,
                                    0.815686334348,
                                    0.713725490196,
                                    1
                                ],
                                "ix": 4
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 5
                            },
                            "r": 1,
                            "bm": 0,
                            "nm": "Fond 1",
                            "mn": "ADBE Vector Graphic - Fill",
                            "hd": false
                        },
                        {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [
                                    -113.912,
                                    -138.897
                                ],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [
                                    83.645,
                                    52.174
                                ],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0.142,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transformer "
                        }
                    ],
                    "nm": "Polyétoile 1",
                    "np": 4,
                    "cix": 2,
                    "bm": 0,
                    "ix": 1,
                    "mn": "ADBE Vector Group",
                    "hd": false
                }
            ],
            "ip": 0,
            "op": 180.00000733155,
            "st": 0,
            "bm": 0
        },
        {
            "ddd": 0,
            "ind": 9,
            "ty": 4,
            "nm": "Fleche 1.1",
            "cl": "1",
            "sr": 1,
            "ks": {
                "o": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 0,
                            "s": [
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 1,
                            "s": [
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 178,
                            "s": [
                                100
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 179,
                            "s": [
                                0
                            ]
                        },
                        {
                            "i": {
                                "x": [
                                    0.833
                                ],
                                "y": [
                                    0.833
                                ]
                            },
                            "o": {
                                "x": [
                                    0.167
                                ],
                                "y": [
                                    0.167
                                ]
                            },
                            "t": 180,
                            "s": [
                                0
                            ]
                        },
                        {
                            "t": 181.000007372281,
                            "s": [
                                100
                            ]
                        }
                    ],
                    "ix": 11
                },
                "r": {
                    "a": 0,
                    "k": 90,
                    "ix": 10
                },
                "p": {
                    "a": 1,
                    "k": [
                        {
                            "i": {
                                "x": 0.833,
                                "y": 0.833
                            },
                            "o": {
                                "x": 0.167,
                                "y": 0.167
                            },
                            "t": 0,
                            "s": [
                                323,
                                247.997,
                                0
                            ],
                            "to": [
                                71.156,
                                -15.315,
                                0
                            ],
                            "ti": [
                                -59.56,
                                -49.451,
                                0
                            ]
                        },
                        {
                            "t": 179.000007290819,
                            "s": [
                                544.894,
                                332.741,
                                0
                            ]
                        }
                    ],
                    "ix": 2,
                    "l": 2
                },
                "a": {
                    "a": 0,
                    "k": [
                        -113.92,
                        -140.445,
                        0
                    ],
                    "ix": 1,
                    "l": 2
                },
                "s": {
                    "a": 0,
                    "k": [
                        101.415,
                        100.392,
                        100
                    ],
                    "ix": 6,
                    "l": 2
                }
            },
            "ao": 1,
            "shapes": [
                {
                    "ty": "gr",
                    "it": [
                        {
                            "ty": "sr",
                            "sy": 2,
                            "d": 1,
                            "pt": {
                                "a": 0,
                                "k": 3,
                                "ix": 3
                            },
                            "p": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 4
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "or": {
                                "a": 0,
                                "k": 15,
                                "ix": 7
                            },
                            "os": {
                                "a": 0,
                                "k": 0,
                                "ix": 9
                            },
                            "ix": 1,
                            "nm": "Tracé polyétoile 1",
                            "mn": "ADBE Vector Shape - Star",
                            "hd": false
                        },
                        {
                            "ty": "rd",
                            "nm": "Sommets arrondis 1",
                            "r": {
                                "a": 0,
                                "k": 3,
                                "ix": 1
                            },
                            "ix": 2,
                            "mn": "ADBE Vector Filter - RC",
                            "hd": false
                        },
                        {
                            "ty": "st",
                            "c": {
                                "a": 0,
                                "k": [
                                    1,
                                    1,
                                    1,
                                    1
                                ],
                                "ix": 3
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 4
                            },
                            "w": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "lc": 1,
                            "lj": 2,
                            "bm": 0,
                            "nm": "Contour 1",
                            "mn": "ADBE Vector Graphic - Stroke",
                            "hd": false
                        },
                        {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [
                                    0.494117676978,
                                    0.815686334348,
                                    0.713725490196,
                                    1
                                ],
                                "ix": 4
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 5
                            },
                            "r": 1,
                            "bm": 0,
                            "nm": "Fond 1",
                            "mn": "ADBE Vector Graphic - Fill",
                            "hd": false
                        },
                        {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [
                                    -113.912,
                                    -138.897
                                ],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [
                                    83.645,
                                    52.174
                                ],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0.142,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transformer "
                        }
                    ],
                    "nm": "Polyétoile 1",
                    "np": 4,
                    "cix": 2,
                    "bm": 0,
                    "ix": 1,
                    "mn": "ADBE Vector Group",
                    "hd": false
                }
            ],
            "ip": 0,
            "op": 180.00000733155,
            "st": 0,
            "bm": 0
        },
        {
            "ddd": 0,
            "ind": 10,
            "ty": 4,
            "nm": "Path",
            "sr": 1,
            "ks": {
                "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 11
                },
                "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 10
                },
                "p": {
                    "a": 0,
                    "k": [
                        600,
                        385.5,
                        0
                    ],
                    "ix": 2,
                    "l": 2
                },
                "a": {
                    "a": 0,
                    "k": [
                        0,
                        0,
                        0
                    ],
                    "ix": 1,
                    "l": 2
                },
                "s": {
                    "a": 0,
                    "k": [
                        100,
                        100,
                        100
                    ],
                    "ix": 6,
                    "l": 2
                }
            },
            "ao": 0,
            "shapes": [
                {
                    "ty": "gr",
                    "it": [
                        {
                            "ind": 0,
                            "ty": "sh",
                            "ix": 1,
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ]
                                    ],
                                    "o": [
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ]
                                    ],
                                    "v": [
                                        [
                                            84.875,
                                            0.75
                                        ],
                                        [
                                            236.5,
                                            1
                                        ]
                                    ],
                                    "c": false
                                },
                                "ix": 2
                            },
                            "nm": "Tracé 1",
                            "mn": "ADBE Vector Shape - Group",
                            "hd": false
                        },
                        {
                            "ty": "st",
                            "c": {
                                "a": 0,
                                "k": [
                                    1,
                                    1,
                                    1,
                                    1
                                ],
                                "ix": 3
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 4
                            },
                            "w": {
                                "a": 0,
                                "k": 20,
                                "ix": 5
                            },
                            "lc": 1,
                            "lj": 1,
                            "ml": 4,
                            "bm": 0,
                            "nm": "Contour 1",
                            "mn": "ADBE Vector Graphic - Stroke",
                            "hd": false
                        },
                        {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [
                                    0.494117676978,
                                    0.815686334348,
                                    0.713725490196,
                                    1
                                ],
                                "ix": 4
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 5
                            },
                            "r": 1,
                            "bm": 0,
                            "nm": "Fond 1",
                            "mn": "ADBE Vector Graphic - Fill",
                            "hd": false
                        },
                        {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [
                                    100,
                                    100
                                ],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transformer "
                        }
                    ],
                    "nm": "Forme 3",
                    "np": 3,
                    "cix": 2,
                    "bm": 0,
                    "ix": 1,
                    "mn": "ADBE Vector Group",
                    "hd": false
                },
                {
                    "ty": "gr",
                    "it": [
                        {
                            "ind": 0,
                            "ty": "sh",
                            "ix": 1,
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [
                                            -12.5,
                                            0.5
                                        ],
                                        [
                                            -11,
                                            17.499
                                        ]
                                    ],
                                    "o": [
                                        [
                                            59,
                                            45.5
                                        ],
                                        [
                                            0.266,
                                            -0.423
                                        ]
                                    ],
                                    "v": [
                                        [
                                            -278,
                                            131
                                        ],
                                        [
                                            -35.5,
                                            57.501
                                        ]
                                    ],
                                    "c": false
                                },
                                "ix": 2
                            },
                            "nm": "Tracé 1",
                            "mn": "ADBE Vector Shape - Group",
                            "hd": false
                        },
                        {
                            "ty": "st",
                            "c": {
                                "a": 0,
                                "k": [
                                    1,
                                    1,
                                    1,
                                    1
                                ],
                                "ix": 3
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 4
                            },
                            "w": {
                                "a": 0,
                                "k": 20,
                                "ix": 5
                            },
                            "lc": 1,
                            "lj": 1,
                            "ml": 4,
                            "bm": 0,
                            "nm": "Contour 1",
                            "mn": "ADBE Vector Graphic - Stroke",
                            "hd": false
                        },
                        {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [
                                    100,
                                    100
                                ],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transformer "
                        }
                    ],
                    "nm": "Forme 2",
                    "np": 2,
                    "cix": 2,
                    "bm": 0,
                    "ix": 2,
                    "mn": "ADBE Vector Group",
                    "hd": false
                },
                {
                    "ty": "gr",
                    "it": [
                        {
                            "ind": 0,
                            "ty": "sh",
                            "ix": 1,
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [
                                            0,
                                            0.502
                                        ],
                                        [
                                            -59.5,
                                            -50
                                        ]
                                    ],
                                    "o": [
                                        [
                                            72,
                                            -15.497
                                        ],
                                        [
                                            0,
                                            -0.5
                                        ]
                                    ],
                                    "v": [
                                        [
                                            -277,
                                            -137.503
                                        ],
                                        [
                                            -53,
                                            -51
                                        ]
                                    ],
                                    "c": false
                                },
                                "ix": 2
                            },
                            "nm": "Tracé 1",
                            "mn": "ADBE Vector Shape - Group",
                            "hd": false
                        },
                        {
                            "ty": "st",
                            "c": {
                                "a": 0,
                                "k": [
                                    1,
                                    1,
                                    1,
                                    1
                                ],
                                "ix": 3
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 4
                            },
                            "w": {
                                "a": 0,
                                "k": 20,
                                "ix": 5
                            },
                            "lc": 1,
                            "lj": 1,
                            "ml": 4,
                            "bm": 0,
                            "nm": "Contour 1",
                            "mn": "ADBE Vector Graphic - Stroke",
                            "hd": false
                        },
                        {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [
                                    100,
                                    100
                                ],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transformer "
                        }
                    ],
                    "nm": "Forme 1",
                    "np": 2,
                    "cix": 2,
                    "bm": 0,
                    "ix": 3,
                    "mn": "ADBE Vector Group",
                    "hd": false
                },
                {
                    "ty": "gr",
                    "it": [
                        {
                            "ind": 0,
                            "ty": "sh",
                            "ix": 1,
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [
                                            0,
                                            0.502
                                        ],
                                        [
                                            -59.5,
                                            -50
                                        ]
                                    ],
                                    "o": [
                                        [
                                            72,
                                            -15.497
                                        ],
                                        [
                                            0,
                                            -0.5
                                        ]
                                    ],
                                    "v": [
                                        [
                                            -277,
                                            -137.503
                                        ],
                                        [
                                            -53,
                                            -51
                                        ]
                                    ],
                                    "c": false
                                },
                                "ix": 2
                            },
                            "nm": "Tracé 1",
                            "mn": "ADBE Vector Shape - Group",
                            "hd": false
                        },
                        {
                            "ty": "st",
                            "c": {
                                "a": 0,
                                "k": [
                                    0.494117677212,
                                    0.8156863451,
                                    0.713725507259,
                                    1
                                ],
                                "ix": 3
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 4
                            },
                            "w": {
                                "a": 0,
                                "k": 24,
                                "ix": 5
                            },
                            "lc": 1,
                            "lj": 1,
                            "ml": 4,
                            "bm": 0,
                            "nm": "Contour 1",
                            "mn": "ADBE Vector Graphic - Stroke",
                            "hd": false
                        },
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ind": 0,
                                    "ty": "sh",
                                    "ix": 1,
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [
                                                    0,
                                                    0.502
                                                ],
                                                [
                                                    -59.5,
                                                    -50
                                                ]
                                            ],
                                            "o": [
                                                [
                                                    72,
                                                    -15.497
                                                ],
                                                [
                                                    0,
                                                    -0.5
                                                ]
                                            ],
                                            "v": [
                                                [
                                                    -277,
                                                    -137.503
                                                ],
                                                [
                                                    -53,
                                                    -51
                                                ]
                                            ],
                                            "c": false
                                        },
                                        "ix": 2
                                    },
                                    "nm": "Tracé 1",
                                    "mn": "ADBE Vector Shape - Group",
                                    "hd": false
                                },
                                {
                                    "ty": "st",
                                    "c": {
                                        "a": 0,
                                        "k": [
                                            1,
                                            1,
                                            1,
                                            1
                                        ],
                                        "ix": 3
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 4
                                    },
                                    "w": {
                                        "a": 0,
                                        "k": 20,
                                        "ix": 5
                                    },
                                    "lc": 1,
                                    "lj": 1,
                                    "ml": 4,
                                    "bm": 0,
                                    "nm": "Contour 1",
                                    "mn": "ADBE Vector Graphic - Stroke",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [
                                            0,
                                            0
                                        ],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [
                                            100,
                                            100
                                        ],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transformer "
                                }
                            ],
                            "nm": "Forme 1",
                            "np": 2,
                            "cix": 2,
                            "bm": 0,
                            "ix": 3,
                            "mn": "ADBE Vector Group",
                            "hd": false
                        },
                        {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [
                                    100,
                                    100
                                ],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transformer "
                        }
                    ],
                    "nm": "Forme 4",
                    "np": 3,
                    "cix": 2,
                    "bm": 0,
                    "ix": 4,
                    "mn": "ADBE Vector Group",
                    "hd": false
                },
                {
                    "ty": "gr",
                    "it": [
                        {
                            "ind": 0,
                            "ty": "sh",
                            "ix": 1,
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [
                                            -12.5,
                                            0.5
                                        ],
                                        [
                                            -11,
                                            17.499
                                        ]
                                    ],
                                    "o": [
                                        [
                                            59,
                                            45.5
                                        ],
                                        [
                                            0.266,
                                            -0.423
                                        ]
                                    ],
                                    "v": [
                                        [
                                            -278,
                                            131
                                        ],
                                        [
                                            -35.5,
                                            57.501
                                        ]
                                    ],
                                    "c": false
                                },
                                "ix": 2
                            },
                            "nm": "Tracé 1",
                            "mn": "ADBE Vector Shape - Group",
                            "hd": false
                        },
                        {
                            "ty": "st",
                            "c": {
                                "a": 0,
                                "k": [
                                    0.494117677212,
                                    0.8156863451,
                                    0.713725507259,
                                    1
                                ],
                                "ix": 3
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 4
                            },
                            "w": {
                                "a": 0,
                                "k": 24,
                                "ix": 5
                            },
                            "lc": 1,
                            "lj": 1,
                            "ml": 4,
                            "bm": 0,
                            "nm": "Contour 1",
                            "mn": "ADBE Vector Graphic - Stroke",
                            "hd": false
                        },
                        {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [
                                    100,
                                    100
                                ],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transformer "
                        }
                    ],
                    "nm": "Forme 5",
                    "np": 2,
                    "cix": 2,
                    "bm": 0,
                    "ix": 5,
                    "mn": "ADBE Vector Group",
                    "hd": false
                },
                {
                    "ty": "gr",
                    "it": [
                        {
                            "ind": 0,
                            "ty": "sh",
                            "ix": 1,
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ]
                                    ],
                                    "o": [
                                        [
                                            0,
                                            0
                                        ],
                                        [
                                            0,
                                            0
                                        ]
                                    ],
                                    "v": [
                                        [
                                            84.875,
                                            0.75
                                        ],
                                        [
                                            236.5,
                                            1
                                        ]
                                    ],
                                    "c": false
                                },
                                "ix": 2
                            },
                            "nm": "Tracé 1",
                            "mn": "ADBE Vector Shape - Group",
                            "hd": false
                        },
                        {
                            "ty": "st",
                            "c": {
                                "a": 0,
                                "k": [
                                    0.541176497936,
                                    0.635294139385,
                                    0.807843208313,
                                    1
                                ],
                                "ix": 3
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 4
                            },
                            "w": {
                                "a": 0,
                                "k": 24,
                                "ix": 5
                            },
                            "lc": 1,
                            "lj": 1,
                            "ml": 4,
                            "bm": 0,
                            "nm": "Contour 1",
                            "mn": "ADBE Vector Graphic - Stroke",
                            "hd": false
                        },
                        {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [
                                    0.494117676978,
                                    0.815686334348,
                                    0.713725490196,
                                    1
                                ],
                                "ix": 4
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 5
                            },
                            "r": 1,
                            "bm": 0,
                            "nm": "Fond 1",
                            "mn": "ADBE Vector Graphic - Fill",
                            "hd": false
                        },
                        {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [
                                    100,
                                    100
                                ],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transformer "
                        }
                    ],
                    "nm": "Forme 6",
                    "np": 3,
                    "cix": 2,
                    "bm": 0,
                    "ix": 6,
                    "mn": "ADBE Vector Group",
                    "hd": false
                }
            ],
            "ip": 0,
            "op": 180.00000733155,
            "st": 0,
            "bm": 0
        }
    ],
    "markers": []
}