/*Images*/
import Image from 'next/future/image';
import viskaliacc from '../../assets/imgs/logos/brands/carrousel/viskaliacc.webp';
import esalink from '../../assets/imgs/logos/brands/carrousel/esalink.webp';
import michelin from '../../assets/imgs/logos/brands/carrousel/michelin.webp';
import araydis from '../../assets/imgs/logos/brands/carrousel/araydis.webp';
import acsh from '../../assets/imgs/logos/brands/carrousel/acsh.webp';
import proxi_fenetres from '../../assets/imgs/logos/brands/carrousel/proxi-fenetres.webp';

/*Carousel*/
import { Autoplay, Pagination } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';

const carousel = () => {

    const items = [
        { id: 1, src: viskaliacc, alt: "ViskaliACC" },
        { id: 2, src: esalink, alt: "Esalink" },
        { id: 3, src: michelin, alt: "Michelin" },
        { id: 4, src: araydis, alt: "Araydis" },
        { id: 5, src: acsh, alt: "ACSH" },
        { id: 6, src: proxi_fenetres, alt: "Proxy'Fenetres" }
    ]


    return (
        <div className="py-12 md:py-16 lg:py-20 overflow-x-hidden">
            <div className="container px-4 mx-auto">
                <div className="text-center mx-auto">
                    <h2 className="mb-4 text-2xl lg:text-2xl font-bold font-heading text-blue-300">
                        <span>Ils nous font confiance !</span>
                    </h2>
                </div>
            </div>
            <div className="container px-4 mx-auto">
                <Swiper slidesPerView={5} autoplay={{ delay: 2500 }} spaceBetween={30} pagination={{ clickable: true }} modules={[Pagination, Autoplay]} mousewheel={{ invert: true }} loop={true}
                    breakpoints={{ 100: { slidesPerView: 1 }, 500: { slidesPerView: 2 }, 768: { slidesPerView: 5 } }}>
                    {items.map((item) => (
                        <SwiperSlide key={item.id}>
                            <span>
                                <Image src={item.src} alt={item.alt} width={800} height={400} layout='responsive' quality={100} />
                            </span>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
}

export default carousel;