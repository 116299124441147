import Link from 'next/link';
/* Image*/
import Image from 'next/image';
import Intersect from '../../assets/imgs/backgrounds/intersect.svg';
import pattern from '../../assets/imgs/elements/pattern.webp';

/* Lottie */
import LottiePath from '../../assets/js/codiceo.js';
/* TextType */
import { Typewriter } from 'react-simple-typewriter';
import { Player } from '@lottiefiles/react-lottie-player';

function upperBody() {
    return (
        <div className="xl:bg-contain bg-top bg-no-repeat -mt-24 pt-24" style={{ backgroundImage: `url(${Intersect.src})` }}>
            <div className="container px-4 mx-auto">
                <div className="pt-12 text-center">
                    <div className="max-w-2xl mx-auto mb-8">
                        <h1 className="text-3xl lg:text-5xl mb-4 font-bold font-heading wow animate__animated animate__fadeIn">Au service de vos idées
                            <br />
                            pour développer <span className="text-blue-300">le futur</span></h1>
                        <p className="text-blueGray-400 text-lg leading-relaxed wow animate__animated animate__fadeIn" style={{ margin: 'auto 0' }}>Nous sommes <strong className="text-blue-300">Codiceo</strong>, l{''}<span className="d-inline text-brand"><Typewriter autoStart={true} loop={true} delay={150} words={['’éditeur', 'es développeurs']} /></span> de vos solutions innovantes</p>
                    </div>
                    <div>
                        <Link href="/contact"><a className="tracking-wide hover-up-2 block sm:inline-block py-4 px-8 mb-4 sm:mb-0 sm:mr-4 text-xs text-white text-center font-semibold leading-none bg-blue-400 hover:bg-blue-500 rounded-full wow animate__animated animate__fadeInUp" >Nous contacter</a></Link>
                        <a className="tracking-wide hover-up-2 block sm:inline-block py-4 px-8 text-xs text-blueGray-500 hover:text-blueGray-600 text-center font-semibold leading-none bg-white rounded-full wow animate__animated animate__fadeInUp" data-wow-delay=".3s" href="#key-features">Notre expertise ?</a>
                    </div>
                </div>
            </div>
            <div className="relative max-w-6xl mt-16 md:mt-8 mb-8 mx-auto">
                <Image src={pattern} alt='pattern' width={1440} height={741} />
                <div className="absolute" style={{ top: "9%", left: "14%", width: "72%" }}>
                    <div className="lottie" />
                    <Player
                        autoplay
                        loop
                        src={LottiePath}
                        controls={false}
                    />
                </div>
            </div>
            <div className="container px-4 mx-auto">
                <div className="flex flex-wrap justify-between pt-8">
                    <div className="hover-up-5 flex w-1/2 lg:w-auto py-4 wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                        <div className="flex justify-center items-center bg-blueGray-50 text-blue-300 rounded-xl h-12 w-12 sm:h-20 sm:w-20 ">
                            <svg fill="none" stroke="#5da5d8" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width={32} height={32}>
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"></path>
                            </svg>
                        </div>
                        <div className="sm:py-2 ml-2 sm:ml-6">
                            <span className="sm:text-2xl font-bold font-heading">+ </span>
                            <span className="sm:text-2xl font-bold font-heading count">8 </span>
                            <span className="sm:text-2xl font-bold font-heading">ans </span>
                            <p className="text-xs sm:text-base text-blueGray-400">Expérience</p>
                        </div>
                    </div>
                    <div className="hover-up-5 flex w-1/2 lg:w-auto py-4 wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                        <div className="flex justify-center items-center bg-blueGray-50 text-blue-300 rounded-xl h-12 w-12 sm:h-20 sm:w-20">
                            <svg width={32} height={32} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z"></path>
                            </svg>
                        </div>
                        <div className="sm:py-2 ml-2 sm:ml-6">
                            <span className="sm:text-2xl font-bold font-heading">+ </span>
                            <span className="sm:text-2xl font-bold font-heading count">10 </span>
                            <span className="sm:text-2xl font-bold font-heading">millions </span>
                            <p className="text-xs sm:text-base text-blueGray-400">Lignes de code écrites</p>
                        </div>
                    </div>
                    <div className="hover-up-5 flex w-1/2 lg:w-auto py-4 wow animate__animated animate__fadeInUp" data-wow-delay=".6s">
                        <div className="flex justify-center items-center bg-blueGray-50 text-blue-300 rounded-xl h-12 w-12 sm:h-20 sm:w-20">
                            <svg width={32} height={32} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"></path>
                            </svg>
                        </div>
                        <div className="sm:py-2 ml-2 sm:ml-6">
                            <span className="sm:text-2xl font-bold font-heading count">100 </span>
                            <span className="sm:text-2xl font-bold font-heading">% </span>
                            <p className="text-xs sm:text-base text-blueGray-400">Réactif</p>
                        </div>
                    </div>
                    <div className="hover-up-5 flex w-1/2 lg:w-auto py-4 wow animate__animated animate__fadeInUp" data-wow-delay=".8s">
                        <div className="flex justify-center items-center bg-blueGray-50 text-blue-300 rounded-xl h-12 w-12 sm:h-20 sm:w-20">
                            <svg width={32} height={32} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"></path>
                            </svg>
                        </div>
                        <div className="sm:py-2 ml-2 sm:ml-6">
                            <span className="sm:text-2xl font-bold font-heading">∞</span>
                            <p className="text-xs sm:text-base text-blueGray-400">Projets innovants</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default upperBody;