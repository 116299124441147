import Head from 'next/head';
import Favicon from '../assets/imgs/logos/favicon.svg';
import img_site from '../assets/imgs/elements/img_site.webp';
import { useEffect } from 'react'
import { useRouter } from 'next/router'

const head = (props) => {
    useEffect(() => {
        const WOW = require('wowjs')
        new WOW.WOW().init()
    })
    const { asPath } = useRouter()
    return (

        <Head>
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
            <title>{props.title}</title>
            <meta name="title" property="og:title" content={props.title} />
            <link rel="shortcut icon" href={Favicon.src} />
            <meta name="description" content={props.description} />
            <meta property="og:locale" content="fr_FR" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:description" content={props.description} />
            <meta name="twitter:title" content={props.title} />
            <meta name="twitter:image" content={props.img ? props.img : img_site} />
            <meta name="image" property="og:image" content={props.img ? props.img : img_site} />
            <link rel="canonical" href={`https://codiceo.fr${asPath}`} />
            <meta property="og:url" content={`https://codiceo.fr${asPath}`} />
            <meta property="og:site_name" content="CODICEO • Les développeurs de vos solutions informatiques" />
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
            <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&family=Poppins:wght@400;500;600;700&display=swap" rel="stylesheet" />
            <link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet"></link>
        </Head>
    );
}

export default head;