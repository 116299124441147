
/* Images */
import Image from 'next/image';
import our_team_sized from '../../assets/imgs/placeholders/our-team-sized.webp';
import blob_tear from '../../assets/imgs/elements/blob-tear.svg';
/* Icon*/
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faLaptop,
    faCode,
    faTabletAlt,
} from "@fortawesome/free-solid-svg-icons";

const centerBody = () => {
    return (
        <div className="py-12 md:py-16 lg:pt-32 overflow-x-hidden overflow-y-hidden" id="key-features">
            <div className="container px-4 mx-auto">
                <div className="flex flex-wrap lg:flex-nowrap">
                    <div className="w-full lg:w-1/2">
                        <div className="lg:py-6 lg:pr-32 wow animate__animated animate__fadeIn" data-wow-delay=".3s">
                            <div className="mb-4">
                                <span className="text-xs py-1 px-3 text-blue-300 font-semibold bg-blue-50 rounded-xl wow animate__animated animate__fadeInDown" data-wow-delay=".9s">Notre expertise ?</span>
                                <h2 className="text-4xl mt-3 font-bold font-heading wow animate__animated animate__fadeInUp" data-wow-delay=".3s">Nous vous accompagnons dans le développement de vos solutions innovantes</h2>
                            </div>
                            <div className="flex items-start py-4 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                                <div className="w-8 mr-5 text-blue-300">
                                    <FontAwesomeIcon icon={faLaptop} prefix="fas" style={{ paddingTop: "5px", fontWeight: "bold" }} width={25} height={25} aria-hidden="true" />
                                </div>
                                <div>
                                    <h3 className="mb-2 text-xl font-semibold font-heading">Logiciel & Application sur mesure</h3>
                                    <p className="text-blueGray-400 leading-loose">Application web, client lourd, maintenance d’un existant, connexions interlogiciels, nos experts sont à votre disposition dans la mise en place d’une solution adaptée.</p>
                                </div>
                            </div>
                            <div className="flex items-start py-4 wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                                <div className="w-8 mr-5 text-blue-300">
                                    <FontAwesomeIcon icon={faTabletAlt} style={{ paddingTop: "5px" }} width={25} height={25} aria-hidden="true" />
                                </div>
                                <div>
                                    <h3 className="mb-2 text-xl font-semibold font-heading">Application mobile multiplateforme</h3>
                                    <p className="text-blueGray-400 leading-loose">De la modélisation graphique au développement, nous réalisons toutes les prestations nécessaires au renforcement de votre visibilité.</p>
                                </div>
                            </div>
                            <div className="flex items-start py-4 wow animate__animated animate__fadeInUp" data-wow-delay=".9s">
                                <div className="w-8 mr-5 text-blue-300">
                                    <FontAwesomeIcon icon={faCode} style={{ paddingTop: "5px" }} width={25} height={25} aria-hidden="true" />
                                </div>
                                <div>
                                    <h3 className="mb-2 text-xl font-semibold font-heading">Site web & Référencement</h3>
                                    <p className="text-blueGray-400 leading-loose">Site vitrine ou site e-commerce, nos sites sont conçus sur-mesure et livrés clé en main, selon vos besoins et en respectant vos exigences.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="relative w-full lg:w-1/2 my-12 lg:my-0">
                        <div className="wow animate__animated animate__fadeInRight" data-wow-delay=".5s">
                            <Image className="relative mx-auto rounded-xl w-full h-full z-10" src={our_team_sized} width={584} height={877} alt="Développeur informatique" />
                            <div className="absolute top-0 left-0 w-40 -ml-12 -mt-12"><Image src={blob_tear} width={160} height={175} alt="Blob" /></div>
                            <div className="absolute bottom-0 right-0 w-40 -mr-12 -mb-12"><Image src={blob_tear} width={160} height={175} alt="Blob" /></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default centerBody;