/* Image*/
import Image from 'next/image';
import eating from '../../assets/imgs/illustrations/eating.svg';
import invision from '../../assets/imgs/illustrations/invision.svg'
import team from '../../assets/imgs/illustrations/team.svg';
import dev from '../../assets/imgs/illustrations/dev.svg';
import space from '../../assets/imgs/illustrations/space.svg';
import web from '../../assets/imgs/illustrations/web.svg';

const sixetapes = () => {
    return (
        <div className="py-20 bg-blueGray-50">
            <div className="container px-4 mx-auto">
                <div className="flex flex-wrap items-center justify-between max-w-2xl lg:max-w-full mb-12">
                    <div className="w-full lg:w-1/2 mb-4 lg:mb-0">
                        <h2 className="text-3xl md:text-4xl font-bold font-heading wow animate__animated animate__fadeInDown">
                            <span>Six étapes </span>
                            <span className="text-blue-300">essentielles</span>
                            <br />
                            <span>à la réussite de votre projet</span>
                        </h2>
                    </div>
                    <div className="w-full lg:w-1/2 lg:pl-16" id="howWeWork">
                        <p className="text-blueGray-400 leading-loose wow animate__animated animate__fadeInUp">Notre équipe est composée d’ingénieurs informatiques qui vous accompagnent dans le développement de vos idées, projets, logiciels.</p>
                    </div>
                </div>
                <div className="flex flex-wrap -mx-3 -mb-6 text-center">
                    <div className="hover-up-5 w-full md:w-1/2 lg:w-1/3 px-3 mb-6" data-wow-delay=".2s">
                        <div className="p-12 bg-white shadow rounded wow animate__animated animate__fadeInUp">
                            <div className="flex w-12 h-12 mx-auto items-center justify-center text-blue-800 font-bold font-heading bg-blue-200 rounded-full ">1</div>
                            <div className="mx-auto my-4"><Image src={eating} alt="Réunion d'initialisation du projet" width={179} height={192} /></div>
                            <h3 className="mb-2 font-bold font-heading">Réunion d'initialisation du projet</h3>
                            <p className="text-sm text-blueGray-400 leading-relaxed">Nous discutons autour d’un café de votre projet, de ses contraintes et de ses spécificités techniques</p>
                        </div>
                    </div>
                    <div className="hover-up-5 w-full md:w-1/2 lg:w-1/3 px-3 mb-6">
                        <div className="p-12 bg-white shadow rounded wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                            <div className="flex w-12 h-12 mx-auto items-center justify-center text-blue-800 font-bold font-heading bg-blue-200 rounded-full">2</div>
                            <div className="mx-auto my-4"><Image src={invision} alt="Réalisation de la maquette" width={275} height={192} /></div>
                            <h3 className="mb-2 font-bold font-heading">Réalisation de la maquette</h3>
                            <p className="text-sm text-blueGray-400 leading-relaxed">Réalisation de la maquette du projet sous forme interactive à l’aide de l’outil InVision</p>
                        </div>
                    </div>
                    <div className="hover-up-5 w-full md:w-1/2 lg:w-1/3 px-3 mb-6">
                        <div className="p-12 bg-white shadow rounded wow animate__animated animate__fadeInUp" data-wow-delay=".6s">
                            <div className="flex w-12 h-12 mx-auto items-center justify-center text-blue-800 font-bold font-heading bg-blue-200 rounded-full">3</div>
                            <div className="mx-auto my-4"><Image src={team} alt="Validation" width={600} height={416} /></div>
                            <h3 className="mb-2 font-bold font-heading">Validation</h3>
                            <p className="text-sm text-blueGray-400 leading-relaxed">Nous apportons les modifications nécessaires sur le maquettage jusqu'à votre validation définitive</p>
                        </div>
                    </div>
                    <div className="hover-up-5 w-full md:w-1/2 lg:w-1/3 px-3 mb-6" data-wow-delay=".2s">
                        <div className="p-12 bg-white shadow rounded wow animate__animated animate__fadeInUp">
                            <div className="flex w-12 h-12 mx-auto items-center justify-center text-blue-800 font-bold font-heading bg-blue-200 rounded-full">4</div>
                            <div className="mx-auto my-4"><Image src={dev} alt="Développement agile" width={220} height={192} /></div>
                            <h3 className="mb-2 font-bold font-heading">Développement agile</h3>
                            <p className="text-sm text-blueGray-400 leading-relaxed">Développement de la solution en mode agile afin de diviser les étapes en plusieurs itérations</p>
                        </div>
                    </div>
                    <div className="hover-up-5 w-full md:w-1/2 lg:w-1/3 px-3 mb-6">
                        <div className="p-12 bg-white shadow rounded wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                            <div className="flex w-12 h-12 mx-auto items-center justify-center text-blue-800 font-bold font-heading bg-blue-200 rounded-full">5</div>
                            <div className="mx-auto my-4"><Image src={space} alt="Tests" width={900} height={623} /></div>
                            <h3 className="mb-2 font-bold font-heading">Tests</h3>
                            <p className="text-sm text-blueGray-400 leading-relaxed">Tests unitaires, fonctionnels, performances. Une étape importante où votre participation est essentielle :)</p>
                        </div>
                    </div>
                    <div className="hover-up-5 w-full md:w-1/2 lg:w-1/3 px-3 mb-6">
                        <div className="p-12 bg-white shadow rounded wow animate__animated animate__fadeInUp" data-wow-delay=".6s">
                            <div className="flex w-12 h-12 mx-auto items-center justify-center text-blue-800 font-bold font-heading bg-blue-200 rounded-full">6</div>
                            <div className="mx-auto my-4"><Image src={web} alt="Mise en production" width={963} height={668} /></div>
                            <h3 className="mb-2 font-bold font-heading">Mise en production</h3>
                            <p className="text-sm text-blueGray-400 leading-relaxed">Mise en ligne du projet, transmission des ressources et du code source. Formation des utilisateurs</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default sixetapes;