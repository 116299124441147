import Link from 'next/link'

const lowerBody = () => {
    return (
        <div className="py-20 bg-green-300">
            <div className="container px-4 mx-auto">
                <div className="text-center mx-auto">
                    <h2 className="mb-4 text-3xl lg:text-3xl text-white font-bold font-heading">
                        <span>Une idée ? Des questions ? Une demande de devis ? </span>
                        <br />
                        <span>Élaborons ensemble votre projet</span>
                    </h2>
                    <br />
                    <Link href="/contact"><a className="block hover-up-2 sm:inline-block py-4 px-8 text-xs text-blueGray-500 hover:text-blueGray-600 text-center font-semibold leading-none bg-white border border-blueGray-200 hover:border-blueGray-300 rounded-full wow animate__ animate__fadeInUp animated" data-wow-delay=".3s"  style={{ visibility: true, animationDelay: '0.3s', animationName: 'fadeInUp' }}>Nous contacter</a></Link>
                </div>
            </div>
        </div>
    );
}

export default lowerBody;